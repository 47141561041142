import { useQuery } from 'react-query';
import { client } from './apiClient';

function getContract() {
  return client('api/1.0/tenant/contract', { api: 'data' }).then(
    (data) => data
  );
}

function getSecurityOptions() {
  return client('api/1.0/tenant/security/options', { api: 'data' });
}

function readOptions() {
  return getSecurityOptions()
    .then((d) => {
      return d;
    })
    .catch(console.log);
}

function useOptions({ onSuccess, ...options } = {}) {
  const { data: securityOptions } = useQuery({
    queryKey: 'securityOptions',
    queryFn: readOptions,
    ...options,
  });

  return securityOptions ?? [];
}

function updateSecurity(dataToUpdate) {
  return client('api/1.0/tenant/security', {
    api: 'data',
    method: 'PUT',
    body: dataToUpdate,
  }).then((res) => res);
}

export { getContract, useOptions, updateSecurity };
