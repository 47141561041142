import React from 'react';

function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false);
  React.useLayoutEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);
  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch]
  );
}

const initialState = { status: 'idle', data: null, error: null };

/* Utils to handle api calls, not the same as apiClient;
 * apiClient is actually making the call; this is a wrapper
 * around those to handle the various state of it */

function useAsync() {
  const [{ status, data, error }, setState] = React.useReducer(
    (s, a) => ({ ...s, ...a }),
    initialState
  );

  const safeSetState = useSafeDispatch(setState);

  const run = React.useCallback(
    (promise) => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`
        );
      }
      safeSetState({ status: 'pending' });
      return promise.then(
        (data) => {
          safeSetState({ data, status: 'resolved' });
          return data;
        },
        (error) => {
          safeSetState({ status: 'rejected', error });
          return error;
        }
      );
    },
    [safeSetState]
  );

  const setData = React.useCallback((data) => safeSetState({ data }), [
    safeSetState,
  ]);
  const setError = React.useCallback((error) => safeSetState({ error }), [
    safeSetState,
  ]);
  const reset = React.useCallback(() => safeSetState(initialState), [
    safeSetState,
  ]);

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
}

export { useAsync };
