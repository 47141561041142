import React from 'react';
import { useAuth } from '../context/authContext';
import FullPageLoader from '../elements/fullPageLoader/fullPageLoader';

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ './authenticatedApp')
);
const UnauthenticatedApp = React.lazy(() => import('./unauthenticatedApp'));

const App = () => {
  const { user } = useAuth();
  return (
    <React.Suspense fallback={<FullPageLoader />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
};

export default App;
