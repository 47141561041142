import React from 'react';
import Icon from '../icon/icon';

function FullPageLoader() {
  return (
    <div className="full-page-loader">
      <Icon
        className="full-page-spinner"
        name={'MdDataUsage'}
        iconProps={{
          size: '3rem',
          color: 'text-blue',
        }}
      />
    </div>
  );
}

function FullPageErrorFallback({ error }) {
  return (
    <div className="full-page-error">
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  );
}

export default FullPageLoader;
export { FullPageErrorFallback };
