import { client, localStorageKey } from './apiClient';

function handleUserResponse({ credentials, status }) {
  window.localStorage.setItem(localStorageKey, true);

  return { credentials, status };
}

function getUser() {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }

  return client('profile').then((data) => data);
}

function editProfile(data) {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }
  return client('profile', { body: data, method: 'PUT' }).then(
    handleUserResponse
  );
}

function editPassword(data) {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }
  return client('password', { body: data, method: 'PUT' }).then(
    handleUserResponse
  );
}

function getMe() {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }

  return client('api/1.0/accounts/me', { api: 'data' }).then((data) => data);
}

function login({ username, password }) {
  return client('auth', { body: { username, password } }).then(
    handleUserResponse
  );
}

function register({
  name,
  family_name,
  email,
  password,
  access_code,
  security_code,
}) {
  return client('register', {
    body: { name, family_name, email, password, access_code, security_code },
  }).then(handleUserResponse);
}

function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

function isLoggedIn() {
  return Boolean(getToken());
}

export { login, register, getToken, getUser, isLoggedIn, getMe, editProfile, editPassword };
export { logout } from './apiClient';
