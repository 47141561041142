import React from 'react';
import classNames from 'classnames';
import * as MaterialDesignIcons from 'react-icons/md';
import * as Typicons from 'react-icons/ti';
import * as FontAwesome from 'react-icons/fa';

/* TODO it's only rounded version, adapt to basic icon use also */
const Icon = ({
  name = 'MdFileDownload',
  iconProps = { size: '1.5rem', color: 'text-blue' },
  oval = false,
  className = '',
}) => {
  let Icon = MaterialDesignIcons[name] || Typicons[name] || FontAwesome[name];
  return (
    <div className={classNames(className, oval ? 'icon-container' : '')}>
      <Icon
        size={iconProps.size}
        className={classNames('inline', iconProps.color)}
      />
    </div>
  );
};

export default Icon;
