import * as auth from './authClient';
import * as tenant from './tenant';

/* Fetches bootstrap data; user, me and contract */
async function bootstrapAppData() {
  let appData = { user: null, me: null, contract: null };

  if (auth.isLoggedIn()) {
    const [user, me] = await Promise.all([auth.getUser(), auth.getMe()]);

    if (me.group === 'tenant') {
      const contract = await tenant.getContract();
      appData['contract'] = contract;
    }
    appData['user'] = user;
    appData['me'] = me;
  }
  return appData;
}

export { bootstrapAppData };
