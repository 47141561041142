import axios from 'axios';
import { queryCache } from 'react-query';
const localStorageKey = 'is_logged';

const okResponses = ['OK', 'Created']; /* TODO add missing ones as we go */

/* A function to handle api calls */

async function client(endpoint, { body, ...customConfig } = {}) {
  const headers = { 'content-type': 'application/json' };

  const API =
    customConfig?.api === 'data'
      ? process.env.REACT_APP_DATA_API_HOST
      : process.env.REACT_APP_AUTH_API_HOST;

  /** This allows to pass in differe http methods */
  customConfig['method'] = customConfig?.method
    ? customConfig.method
    : body
    ? 'post'
    : 'get';

  const config = {
    url: `${API}/${endpoint}`,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
  };

  if (body) {
    config.data = body;
  }

  return axios(config)
    .then((r) => {
      if (r.status === 401) {
        logout();
        // refresh the page for them
        window.location.assign(window.location);
        return;
      }
      const data = r.data;
      if (okResponses.includes(r.statusText)) {
        return data;
      } else {
        return Promise.reject(data);
      }
    })
    .catch((error) => {
      if (error.response.data.error) {
        return Promise.reject({ message: error.response.data.error });
      } else {
        return Promise.reject({ message: 'Something went wrong' });
      }
    });
}

function handleLogoutResponse() {
  queryCache.clear();
  window.localStorage.removeItem(localStorageKey);
}

function logout() {
  client('logout').then(handleLogoutResponse);
}

export { client, localStorageKey, logout };
